import gsap  from "gsap"
import { TimelineMax, MotionPathPlugin } from "gsap/all"

gsap.registerPlugin(MotionPathPlugin)
export class cloudAnim {

    constructor(props){
        this.item1 = props.item1
        this.item2 = props.item2
        this.item3 = props.item3
        this.tl = new TimelineMax({repeat:-1, yoyo:true});
    }

    stopAnim () {
        this.tl.kill()
        gsap.to(this.item1, {
            x: 200,
            opacity: 0,
            duration: 1
        })
        gsap.to(this.item2, {
            x: -200,
            opacity: 0,
            duration: 1
        })
        gsap.to(this.item3, {
            opacity: 0,
            duration: 1
        })

    }

    startAnim () {
        gsap.to(this.item1, { opacity: 1, duration: 1 })
        gsap.to(this.item2, { opacity: 1, duration: 1 })
        gsap.to(this.item3, { opacity: 1, duration: 1 })

        this.tl.to(this.item1, {duration: 20, x:100})
        .to(this.item2, {duration: 20, x:-100}, 0 )
    }
}

export class snowballAnim {

    constructor(props){
        this.snow1 = props.snow1
        this.snow2 = props.snow2
        this.snow3 = props.snow3
        this.snow4 = props.snow4
        this.snow5 = props.snow5
        this.headerName = props.headerName
        this.headerWebdev = props.headerWebdev
        this.start = props.start
        this.circle = this.headerName.querySelector('#intro-circle')
        this.tl = gsap.timeline()
    }

    installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
        let mql = window.matchMedia(mediaQuery);
        mql.addListener(function (e) { return layoutChangedCallback(e.matches); });
        layoutChangedCallback(mql.matches);
    }

    stopAnim () {
        this.tl.kill()
        this.snow1.remove()
        this.snow2.remove()
        this.snow3.remove()
        this.snow4.remove()
        this.snow5.remove()
        this.start.remove()
        gsap.to(this.headerName.querySelector('span'), {
            opacity: 0,
            delay: 1
        })
        gsap.to(this.headerWebdev, {
            opacity: 0,
            delay: .5,
            onComplete: () => {
                this.headerWebdev.remove()
            }
        })

        
        const circleTimeline = gsap.timeline()

        this.installMediaQueryWatcher("(max-width: 700px)", matches => {
  
            if (matches) {
                circleTimeline.to(this.circle, {
                    y: -150,
                    duration: 1,
                    ease: "ease",
                    delay: 1.5,
                }).to(this.circle, {
                    y: -150,
                    scale: 100,
                    duration: 5
                })
            } else {
                circleTimeline.to(this.circle, {
                    motionPath: "m 0 0 q 150 400 150 0",
                    duration: 1,
                    // ease: "none",
                    delay: 1.5,
                }).to(this.circle, {
                    scale: 100,
                    duration: 3
                })
            }
          });
    }

    startAnim () {

        this.installMediaQueryWatcher("(max-width: 700px)", matches => {
            if (matches) {
                this.tl.to(this.headerName, {duration: 2, opacity: 1})
                .to(this.headerWebdev, {duration: 2, opacity: 1}, "=-1")
                .to(this.circle, { y: -25, yoyo: true, repeat: -1 })
                .to(this.snow1, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 1)
                .to(this.snow2, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 5)
                .to(this.snow3, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 4)
                .to(this.snow4, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 3)
                .to(this.snow5, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 2)
            } else {
                this.tl.to(this.headerName, {duration: 2, opacity: 1})
                .to(this.headerWebdev, {duration: 2, opacity: 1}, "=-1")
                .to(this.snow1, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 1)
                .to(this.snow2, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 5)
                .to(this.snow3, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 4)
                .to(this.snow4, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 3)
                .to(this.snow5, {duration: 7, rotation:90, y: 700, opacity: 0, repeat: -1}, 2)
            }
          });
    }
}

