import * as React from "react"
import * as showcaseStyles from "../stylesheets/showcase.module.scss"
import TransitionLink from "gatsby-plugin-transition-link"
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { cloudAnim, snowballAnim } from "../gsap/intro"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  let cloud1 = React.useRef(null)
  let cloud2 = React.useRef(null)
  let cloud3 = React.useRef(null)
  let headerName = React.useRef(null)
  let headerWebdev = React.useRef(null)
  let start = React.useRef(null)
  let snowball1 = React.useRef(null)
  let snowball2 = React.useRef(null)
  let snowball3 = React.useRef(null)
  let snowball4 = React.useRef(null)
  let snowball5 = React.useRef(null)

  let moveClouds;
  let moveSnow;

  React.useEffect(() => {
    moveClouds = new cloudAnim({
      item1: cloud1,
      item2: cloud2,
      item3: cloud3
    })

    moveSnow = new snowballAnim({
      snow1: snowball1,
      snow2: snowball2,
      snow3: snowball3,
      snow4: snowball4,
      snow5: snowball5,
      headerName,
      headerWebdev,
      start
    })

    moveClouds.startAnim()
    moveSnow.startAnim()
  })

  const exitAnim = function(exit, node) {
    moveSnow.stopAnim()
    moveClouds.stopAnim()
  }

  const enterAnim = function(entry, node) {
    return
  }

  return (
    
    <div className={showcaseStyles.container}>
          <SEO title="Joseph Scutella | Web Developer" />
          
        <div ref={(el) => (cloud1 = el)}className={showcaseStyles.cloud1}>
          <Img fadeIn={false} fluid={data.cloud1.childImageSharp.fluid} />
        </div>
        <div  ref={(el) => (cloud2 = el)}className={showcaseStyles.cloud2}>
          <Img fadeIn={false} fluid={data.cloud2.childImageSharp.fluid} />
        </div>
        <div ref={el => (cloud3 = el)}className={showcaseStyles.cloud3}>
          <Img fadeIn={false} fluid={data.cloud3.childImageSharp.fluid} />
        </div>

        <div ref={el => snowball1 = el}className={showcaseStyles.snowball1}>
          <Img fluid={data.snowball.childImageSharp.fluid} />
        </div>
        <div ref={el => snowball2 = el}className={showcaseStyles.snowball2}>
          <Img fluid={data.snowball.childImageSharp.fluid} />
        </div>
        <div ref={el => snowball3 = el} className={showcaseStyles.snowball3}>
          <Img fluid={data.snowball.childImageSharp.fluid} />
        </div>
        <div ref={el => snowball4 = el}className={showcaseStyles.snowball4}>
          <Img fluid={data.snowball.childImageSharp.fluid} />
        </div>
        <div ref={el => snowball5 = el} className={showcaseStyles.snowball5}>
          <Img fluid={data.snowball.childImageSharp.fluid} />
        </div>

        <div className={showcaseStyles.headerContainer}>
            <h1 ref={(el) => (headerName = el)} className={showcaseStyles.headerName}><span>Joseph Scutella</span>
              <TransitionLink 
                id="intro-circle"
                className={showcaseStyles.headerDot}
                to="/about"
                exit={{
                  trigger: ({ exit, node }) => {exitAnim(exit, node)},
                  length: 3.5,
                  
                }}
                entry={{
                  delay: 3.5,
                  trigger: ({ entry, node }) => {enterAnim(entry, node)},
                }}
                ></TransitionLink>
            </h1>
        </div>
        <div className={showcaseStyles.webdevContainer}>
            <h2 ref={(el) => (headerWebdev = el)} className={showcaseStyles.headerWebdev}>Web Developer</h2>
        </div>
        <div ref={el => (start = el)} >
          <TransitionLink
            className={showcaseStyles.start}
            to="/about"
            exit={{
              trigger: ({ exit, node }) => {exitAnim(exit, node)},
              length: 3.5,
            }}
            entry={{
              delay: 3.5,
              trigger: ({ entry, node }) => {enterAnim(entry, node)},
            }}
          ><FontAwesomeIcon className={showcaseStyles.startIcon} icon={faLongArrowAltRight} />
          </TransitionLink>
          </div>
    </div>
  )
}

export default IndexPage

export const query = graphql`
query {
  cloud2: file(relativePath: {eq: "assets/whatidocloud2.png"}) {
    id
    relativePath
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  cloud1: file(relativePath: {eq: "assets/whatidocloud1.png"}) {
    id
    relativePath
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  cloud3: file(relativePath: {eq: "assets/whatidocloud3.png"}) {
    id
    relativePath
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
  snowball: file(relativePath: {eq: "assets/snowball.png"}) {
    id
    relativePath
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}


`
