// extracted by mini-css-extract-plugin
export var cloud1 = "showcase-module--cloud1--SC1MH";
export var cloud2 = "showcase-module--cloud2---09p-";
export var cloud3 = "showcase-module--cloud3--lcBn+";
export var container = "showcase-module--container--Bj7UJ";
export var headerDot = "showcase-module--headerDot--KV+By";
export var headerName = "showcase-module--headerName--pZp69";
export var headerWebdev = "showcase-module--headerWebdev--kp2Hv";
export var introBtn = "showcase-module--introBtn--q6I3c";
export var snowball1 = "showcase-module--snowball1--Ua-Zo";
export var snowball2 = "showcase-module--snowball2--mlAQ7";
export var snowball3 = "showcase-module--snowball3--egdz9";
export var snowball4 = "showcase-module--snowball4--HelyP";
export var snowball5 = "showcase-module--snowball5--5ZQHu";
export var start = "showcase-module--start--AplVA";
export var startIcon = "showcase-module--startIcon--a+jlS";
export var webdevContainer = "showcase-module--webdevContainer--NXKNZ";